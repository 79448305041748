import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const DisclaimerContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allDisclaimerContentJson {
          edges {
            node {
              id
              bannerImage
              paragraphs {
                para
              }
              title
              subtitle
            }
          }
        }
      }
    `}
    render={({ allDisclaimerContentJson: { edges: disclaimer } }) =>
      children(
        disclaimer.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

DisclaimerContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default DisclaimerContainer;
