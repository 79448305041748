import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import SEO from '../SEO';
import Container from '../Container';
import ContentArea from '../ContentArea';
import Title from '../Title';
import SubTitle from '../SubTitle';
import ListGroup from '../ListGroup';
import PageBanner from '../PageBanner';
import DisclaimerContainer from '../../containers/DisclaimerContainer';

const Disclaimer = ({ page }) => {
  return (
    <DisclaimerContainer>
      {(disclaimer) => {
        const pageName = page || 'disclaimer';
        const detailInfo = disclaimer
          .map((ele) => {
            return ele.id === pageName ? ele : null;
          })
          .find((el) => el);
        return (
          <Box>
            <Box>
              <SEO />
              {/* <SEO description="" title="Disclaimer" /> */}

              <PageBanner image={detailInfo.bannerImage} />
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 10,
                  mt: [3, null, null, null, 7],
                  width: ['100%', null, null, null, 'maxWidths.content'],
                }}
              >
                <ContentArea
                  sx={{
                    ml: ['auto', null, null, null, 'auto'],
                    mr: ['auto', null, null, null, 8],
                    pl: [null, null, null, null, 9],
                    width: ['100%', null, null, null, 'calc(66% + 120px)'],
                  }}
                >
                  <Title
                    sx={{
                      color: 'grays.4',
                      fontFamily: 'tertiary',
                      fontSize: 9,
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      lineHeight: '35px',
                      mb: '27px',
                      mt: [4, null, null, null, 'unset'],
                    }}
                  >
                    {detailInfo.title}
                  </Title>
                  <SubTitle
                    sx={{
                      color: 'warning',
                      fontFamily: 'tertiary',
                      fontSize: 4,
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      lineHeight: '28px',
                      mb: '28px',
                    }}
                  >
                    {detailInfo.subtitle}
                  </SubTitle>
                  <ListGroup
                    listSx={{
                      mb: '28px',
                    }}
                    sx={{
                      fontWeight: 'regular',
                    }}
                    wrapperSx={{
                      breakInside: 'avoid',
                      color: 'grays.3',
                      fontFamily: 'tertiary',
                      fontSize: 3,
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      lineHeight: '28px',
                      pageBreakInside: 'avoid',
                    }}
                  >
                    {detailInfo.paragraphs.length > 0 &&
                      detailInfo.paragraphs.map((detail, index) => (
                        <span key={`intro-${index}`}>{detail.para}</span>
                      ))}
                  </ListGroup>
                </ContentArea>
              </Container>
            </Box>
          </Box>
        );
      }}
    </DisclaimerContainer>
  );
};

Disclaimer.propTypes = {
  page: PropTypes.string,
};

Disclaimer.defaultProps = {
  page: '',
};

export default Disclaimer;
