import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import Disclaimer from '../components/Disclaimer/index';

const DisclaimerRouter = (props) => {
  return (
    <Router>
      <Disclaimer page="disclaimer" path="/disclaimer" {...props} />
      <NotFoundPage default />
    </Router>
  );
};

export default DisclaimerRouter;
